import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PerformanceReportSettingsProvider extends HttpRequest {
  getPerformanceReportSettings (query) {
    this.setHeader(getAuthToken())
    return this.get('performance-report-settings', query)
  }

  getPerformanceReportSettingsById (id) {
    this.setHeader(getAuthToken())
    return this.get(`performance-report-settings/${id}`)
  }

  createPerformanceReportSettings (payload) {
    this.setHeader(getAuthToken())
    return this.post('performance-report-settings', payload)
  }

  updatePerformanceReportSettings (payload) {
    this.setHeader(getAuthToken())
    return this.put(`performance-report-settings/${payload.id}`, payload)
  }

  deletePerformanceReportSettings (id) {
    this.setHeader(getAuthToken())
    return this.delete(`performance-report-settings/${id}`)
  }
}

export default PerformanceReportSettingsProvider
