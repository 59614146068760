import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ReviewWarehousesProvider extends HttpRequest {
  getReviewWarehouses () {
    this.setHeader(getAuthToken())
    return this.get('review-warehouse')
  }

  createManyReviewWarehouses (reviewWarehousesForm) {
    this.setHeader(getAuthToken())
    return this.post('review-warehouse', reviewWarehousesForm)
  }
}

export default ReviewWarehousesProvider
